.kanit { font-family: 'Kanit', sans-serif; }
.lora { font-family: 'Lora', serif; }
.montserrat { font-family: 'Montserrat', sans-serif; }
.mukta { font-family: 'Mukta', sans-serif; }
.openSans { font-family: 'Open Sans', sans-serif; }
.oswald { font-family: 'Oswald', sans-serif; }
.poorStory { font-family: 'Poor Story', cursive; }
.poppins { font-family: 'Poppins', sans-serif; }
.ptSansNarrow { font-family: 'PT Sans Narrow', sans-serif; }
.roboto { font-family: 'Roboto', sans-serif; }
.robotoCondensed { font-family: 'Roboto Condensed', sans-serif; }
.robotoMono { font-family: 'Roboto Mono', monospace; }
.rubikBubbles { font-family: 'Rubik Bubbles', cursive; }
.workSans { font-family: 'Work Sans', sans-serif; }
.xanhMono { font-family: 'Xanh Mono', monospace; }
.Orbitron { font-family: "Orbitron", sans-serif; }
