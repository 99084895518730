
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Custom Scrollbar Styles */
.scrollbar-custom::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #2a2a2a; /* Color of the track */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: #4A5568; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners of the scrollbar thumb */
  border: 2px solid #2a2a2a; /* Creates padding around the scrollbar thumb */
}

body{background-color: #e7e7e7b1;

  padding: 1em;

}
/* Change scrollbar thumb color to cyan-400 on hover or active */
.scrollbar-custom::-webkit-scrollbar-thumb:hover,
.scrollbar-custom::-webkit-scrollbar-thumb:active {
  background-color: #22d3ee; /* Tailwind's cyan-400 */
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9999;
  float: left;
    
  margin-top: -6vh;
}

#v0{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#set-height{
  display: block;
}

section{
  z-index: 1;
  position: relative;
}

.content{
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  top:33.3%
}
.content2{
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  top:50%
}
.content3{
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  top:20%
}
h3{

}

p{
 
}

section:nth-of-type(1){
height: 75vh;
overflow: hidden; 

}

section:nth-of-type(2){
 height: 900px;
 
}

section:nth-of-type(3){
  height: 5500px;
}
section:nth-of-type(4){
  height: 2200px;
}
section:nth-of-type(5){
  height:1000px;
}
section:nth-of-type(6){
  height: 1800px;
}

section:nth-of-type(7){
  height: 3200px;
}
section:nth-of-type(8){
  height: 1000px;
}
section:nth-of-type(9){
  height: 4350px;
}
section:nth-of-type(10){
  height: 5000px;
}


.gradient-bg {
  background: rgb(0,0,0);
  background: radial-gradient(circle,  rgb(111, 207, 239) 30%, rgb(255, 255, 255) 100%);
}

.sticky-center {
  position: -webkit-sticky;
  position: sticky;
  top: 60%;
  transform: translateY(-50%);
}

